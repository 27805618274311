import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { API_URL, shorttitle, theme, title } from "../../config/Config";
import Spinner from "../Spinner";
import { useNavigate, Link } from "react-router-dom";
import { get, post } from "../../config/apis";
import dateFormat from "dateformat";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import toasted from "../../config/toast";

function Gtc() {

  const navigate = useNavigate();
  const [currentPage, setcurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [perPage, setPerPage] = useState(10);
  const [user, setUser] = useState('');
  const [records, setrecords] = useState(0);
  const [search, setSearch] = useState('')
  const [value, setValue] = useState('')
  const [sorting, setSorting] = useState({ key: '', ascending: false })
  let [p, setP] = useState(' ')


  const handleFetch = async () => {
    setUser(null)
    setLoading(true);
    var passcon= '';
    if(value !== '' && value !== undefined) {
       passcon = `&search=symbol&value=${value?.toLocaleLowerCase()}`;
    } 
    const res = await get(`${API_URL}/market/elpMarketDetails?sort=${sorting.key}&page=${currentPage}&limit=${perPage}${passcon}`,
      {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("Token"),
      }
    );
    if (res?.status === "success") {
      setUser(res);

      setrecords(res.total);
      setLoading(false);
      setP(res.page + 1)
    } else if (res?.status === "error") {
      localStorage.clear();
      navigate("/login");
    }
  }



  const handlePageChange = async (e) => {
    const newOffset = (e.selected + 1);
    setcurrentPage(newOffset);
  };

  useEffect(() => {
    handleFetch(); // eslint-disable-next-line
  }, [currentPage, perPage, search, value]);

  const submitForm = async (e) => {
    e.preventDefault();
    // handleFetch();
  }

  const applySorting = (key, ascending) => {
    if (ascending) {
      setSorting({ key: '', ascending: false });
      setSorting({ key: '-' + key, ascending: ascending });
      handleFetch();
    } else {
      setSorting({ key: '', ascending: false });
      setSorting({ key: key, ascending: ascending });
      handleFetch();

    }

  }
  // credit fund //

  // Credit button Modal 
  const [openT, setOpenT] = useState(false);
  const onOpenModalT = () => setOpenT(true);
  const onCloseModalT = () => setOpenT(false);


  const creditModalSubmit = async (e) => {
    e.preventDefault();
    const para =
    {
      username: userName,
      amount: amountT,
      coinid: coinId,
      symbol: symbol,
      type: walletType
    }

    const res = await post(`${API_URL}/fund/credit-fund`, para, {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("Token")
    })
    if (res) {
      toasted.success(res.message)
      onCloseModalT()
      // navigate('/elpmarket')
    } else {
      toasted.error(res.message)
    }
  }
  const [coinId, setcoinId] = useState()
  const [symbol, setSymbol] = useState()
  const creditIncome = (coin_id, symbol) => {
    onOpenModalT()
    setcoinId(symbol)
    setSymbol(coin_id)
  }
  const [amountT, setAmountT] = useState('')
  const [userName, setUserName] = useState('')
  const [walletType, setWalletType] = useState('main_account')

  const direction = (e) => {
    let val = e.target.value;
    if (val === 'Main Account') {
      setWalletType('main_account')
    }
    else if (val === 'Trading Account') {
      setWalletType('trade_account')

    }
    else if (val === 'Funding Account') {
      setWalletType('funding_account')

    }
  }



  // credit fund end //

  // Debit Fund //

  // Debit button Modal 
  const [openDebit, setOpenDebit] = useState(false);
  const onOpenModalDebit = () => setOpenDebit(true);
  const onCloseModalDebit = () => setOpenDebit(false);

  const debitModalSubmit = async (e) => {
    e.preventDefault();
    const para =
    {
      username: userNameDebit,
      amount: amountDebit,
      coinid: coinIdDebit,
      symbol: symbolDebit,
      type: walletTypeDebit
    }

    const res = await post(`${API_URL}/fund/debit-fund`, para, {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("Token")
    })
    if (res) {

      toasted.success(res.message)

      // navigate('/elpmarket')
    } else {
      toasted.error(res.message)
    }
  }
  const [amountDebit, setAmountDebit] = useState('')
  const [userNameDebit, setUserNameDebit] = useState('')
  const [walletTypeDebit, setWalletTypeDebit] = useState('')
  const [coinIdDebit, setcoinIdDebit] = useState()
  const [symbolDebit, setSymbolDebit] = useState()
  const debitIncome = (coin_id, symbol) => {
    onOpenModalDebit()
    setcoinIdDebit(symbol)
    setSymbolDebit(coin_id)

  }


  const directionDebit = (e) => {
    let val = e.target.value;
    if (val === 'Main Account') {
      setWalletTypeDebit('main_account')
    }
    else if (val === 'Trading Account') {
      setWalletTypeDebit('trade_account')

    }
    else if (val === 'Funding Account') {
      setWalletTypeDebit('funding_account')

    }
  }

  // Debit Fund End //




  const handleChange = async (e) => {
    e.preventDefault();
    setPerPage(e.target.value);
  }



  return (
    <>
      <div className="content-wrapper-new" /* style={{ minHeight: "1302.4px" }} */>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6"> <h1>{title} MARKET</h1> </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"> <Link to="/">Home</Link> </li>
                  <li className="breadcrumb-item active">{title} Market</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <div className="card card-primary card-outline card-outline-tabs mb-11">
          <div className="card-body p-0">
            <div className="tab-content" id="custom-tabs-four-tabContent">
              <div className="tab-pane fade active show" id="detail-tab">
                <div className="row">
                  <div className="col-12">
                    <div className="">

                      <div className="row mb-2">
                        <div className="col-xl-10 col-lg-8 col-md-7 ">
                          <div className="d-flex justify-content-between">
                            <div className="form-group m-0 text-nowrap">
                              <span>Show</span>
                              <select className="form-control  text-center w-auto d-inline mx-2" onChange={handleChange}>
                                <option defaultValue={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                              <span>entries</span>
                            </div>
                            <form action="#" method="get" >
                              <select onChange={(e) => setSearch(e.target.value)} name="search" className="btn btn-active">
                                {/* <option defaultValue={''}>Search By</option> */}
                                <option value={'symbol'}>COIN</option>
                              </select>
                            </form>


                          </div>

                        </div>

                        <div className="col-xl-2 col-lg-4 col-md-5">
                          <form action="#" method="get" >

                            <div className="card-tools m-0">
                              <div className="input-group input-group-sm" style={{ width: '100%' }}>
                                <input type="text" name="value" className="form-control float-right" placeholder="Search" onChange={(e) => setValue(e.target.value)} />
                                <div className="input-group-append">
                                  <button type="submit" className="btn btn-default" onClick={submitForm} >
                                    <i className="fas fa-search" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div className="card-body table-responsive p-0">
                        <table className="table text-nowrap">
                          <thead className="text-center">
                            <tr>
                              <th>#</th>
                              <th onClick={() => applySorting('symbol', !sorting.ascending)} >COIN
                                <i className={sorting.key === '-symbol' || sorting.key === 'symbol' ? sorting.ascending === true && sorting.key === '-symbol' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th>ACTIONS</th>
                              <th>TRANSFER</th>
                              <th onClick={() => applySorting('current_price', !sorting.ascending)} >LAST PRICE
                                <i className={sorting.key === '-current_price' || sorting.key === 'current_price' ? sorting.ascending === true && sorting.key === '-current_price' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('min_withraw_limit', !sorting.ascending)} >MIN WITHDRAW LIMIT
                                <i className={sorting.key === '-min_withraw_limit' || sorting.key === 'min_withraw_limit' ? sorting.ascending === true && sorting.key === '-min_withraw_limit' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} />
                              </th>
                              <th onClick={() => applySorting('max_withraw_limit', !sorting.ascending)} >MAX WITHDRAW LIMIT
                                <i className={sorting.key === '-max_withraw_limit' || sorting.key === 'max_withraw_limit' ? sorting.ascending === true && sorting.key === '-max_withraw_limit' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} />
                              </th>
                              <th onClick={() => applySorting('fees', !sorting.ascending)} >FEES
                                <i className={sorting.key === '-fees' || sorting.key === 'fees' ? sorting.ascending === true && sorting.key === '-fees' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} />
                              </th>
                              <th onClick={() => applySorting('bep20', !sorting.ascending)} >BEP 20
                                <i className={sorting.key === '-bep20' || sorting.key === 'bep20' ? sorting.ascending === true && sorting.key === '-bep20' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} />
                              </th>
                              <th onClick={() => applySorting('erc20', !sorting.ascending)} >ERC 20
                                <i className={sorting.key === '-erc20' || sorting.key === 'erc20' ? sorting.ascending === true && sorting.key === '-erc20' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} />
                              </th>
                              <th onClick={() => applySorting('trc20', !sorting.ascending)} >TRC 20
                                <i className={sorting.key === '-trc20' || sorting.key === 'trc20' ? sorting.ascending === true && sorting.key === '-trc20' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} />
                              </th>
                              <th onClick={() => applySorting('sta20', !sorting.ascending)} >{shorttitle} 20
                                <i className={sorting.key === '-sta20' || sorting.key === 'sta20' ? sorting.ascending === true && sorting.key === '-sta20' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} />
                              </th>
                              <th onClick={() => applySorting('withdraw_status', !sorting.ascending)} >WITHDRAW STATUS
                                <i className={sorting.key === '-withdraw_status' || sorting.key === 'withdraw_status' ? sorting.ascending === true && sorting.key === '-withdraw_status' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} />
                              </th>
                              <th onClick={() => applySorting('withdraw_message', !sorting.ascending)} >WITHDRAW MESSAGE
                                <i className={sorting.key === '-withdraw_message' || sorting.key === 'withdraw_message' ? sorting.ascending === true && sorting.key === '-withdraw_message' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} />
                              </th>
                              {/* <th onClick={() => applySorting('sparkline_in_7d', !sorting.ascending)} >MARKET
                              <i className={sorting.key === '-sparkline_in_7d' || sorting.key === 'sparkline_in_7d' ? sorting.ascending === true && sorting.key === '-sparkline_in_7d' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                            </th> */}
                              <th onClick={() => applySorting('created_at', !sorting.ascending)} >CREATED AT
                                <i className={sorting.key === '-created_at' || sorting.key === 'created_at' ? sorting.ascending === true && sorting.key === '-created_at' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('updated_at', !sorting.ascending)} >UPDATED AT
                                <i className={sorting.key === '-updated_at' || sorting.key === 'updated_at' ? sorting.ascending === true && sorting.key === '-updated_at' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading && <Spinner />}

                            {user?.data?.data?.map((value, i) => {
                              return (
                                <tr key={i}>
                                  <td>{p++}</td>
                                  <td>
                                    <div className="d-flex">
                                      <img src={value.marketData[0] ? value.marketData[0].image : null} className="mr-3" alt="logo" style={{ width: '40px' }} />
                                      {(value.symbol).toUpperCase()}
                                      <br />  ({value.name.toLowerCase()})
                                    </div>
                                  </td>
                                  <td><Link to={`/gtcmarket/${value._id}`}><button className="btn btn-sm px-4 text-dark" style={{ backgroundColor: theme }}>Edit</button></Link></td>

                                  <td>
                                    <button className="btn btn-sm px-4 text-dark mx-2" style={{ backgroundColor: theme }} onClick={() => creditIncome(value.symbol, value.coin_id)} > Credit </button>
                                    <button className="btn btn-sm px-4 text-dark " style={{ backgroundColor: theme }} onClick={() => debitIncome(value.symbol, value.coin_id)} > Debit </button>
                                  </td>

                                  <td>{value.marketData[0] ? value.marketData[0].current_price : null}</td>
                                  <td>{value.min_withdraw_limit}</td>
                                  <td>{value.max_withdraw_limit}</td>
                                  <td>{value.fees}</td>
                                  <td>{(value.bep20?.status === true) ? <div className="text-success font-weight-bold">Open</div> : <div className="text-danger font-weight-bold">Close</div>}</td>
                                  <td>{(value.erc20?.status === true) ? <div className="text-success font-weight-bold">Open</div> : <div className="text-danger font-weight-bold">Close</div>}</td>
                                  <td>{(value.trc20?.status === true) ? <div className="text-success font-weight-bold">Open</div> : <div className="text-danger font-weight-bold">Close</div>}</td>
                                  <td>{(value.sta20?.status === true) ? <div className="text-success font-weight-bold">Open</div> : <div className="text-danger font-weight-bold">Close</div>}</td>
                                  <td>{(value?.withdraw_status === true) ? <div className="text-success font-weight-bold">Open</div> : <div className="text-danger font-weight-bold">Close</div>}</td>
                                  <td>{value.withdraw_message}</td>
                                  <td>{dateFormat(value.created_at, "UTC:dd-mm-yyyy TT hh:mm:ss")}</td>
                                  <td>{dateFormat(value.updated_at, "UTC:dd-mm-yyyy TT hh:mm:ss")}</td>
                                </tr>
                              );
                            })}
                          </tbody>

                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {records > perPage ?
                  <div className="row mt-4  ">
                    <div className="col-12 text-right">
                      <div className="dataTables_info" id="example1_info" role="status" aria-live="polite" >
                        Showing {currentPage > 1 ? currentPage * perPage - perPage + 1 : currentPage} to {currentPage * perPage} of {records} entries
                      </div>
                    </div>

                    <div className="col-12">
                      <ReactPaginate
                        pageCount={user?.total > 0 ? Math.ceil(user?.total / perPage) : 1}
                        onPageChange={handlePageChange}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={2}
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        renderOnZeroPageCount={1}
                      />
                    </div>
                  </div>
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CREDIT FUND MODAL */}
      <Modal open={openT} onClose={onCloseModalT}
        classNames={{
          overlay: 'customOverlay',
          modal: 'customModal_S',
        }} center  >
        <div className="card-header p-0">
          <h3 className="title-modal">Credit</h3>
        </div>
        <div className="modail-data">
          <div className="modal-body">
            <form onSubmit={creditModalSubmit}>
              <label htmlFor="usr">Username:</label>
              <div className="input-group">
                <span className="input-group-text">  <FormatListNumberedRtlIcon /></span>
                <input type='number' className="form-control" onChange={(e) => setUserName(e.target.value)} placeholder='000000' />
              </div>

              <label htmlFor="accountName">Wallets:</label>
              <div className="input-group">
                <span className="input-group-text">    <FormatListNumberedRtlIcon /></span>
                <select className="form-control" name="browser" id="browser" onChange={direction} placeholder='Trading Account'>
                  <option value='Main Account'> Main Account </option>
                  <option value="Trading Account"> Trading Account </option>
                  <option value="Funding Account"> Funding Account</option>
                </select>
              </div>

              <label htmlFor="usr">Amount:</label>
              <div className="input-group">
                <input type="number"
                  onChange={(e) => setAmountT(Number(e.target.value))}
                  className="form-control" id="usr" placeholder="Amount" />
                {/* <button type="button" className="btn_max">Max </button> */}
              </div>


              <div className="text-center mt-3">
                {amountT && userName ? (<button type="submit" className="btn trade-btn-a pl-5 pr-5 btn-hover" >Submit</button>) : (<button type="submit" disabled className="btn    pl-5 pr-5 btn-hover" style={{ backgroundColor: 'gray' }} title="Username & Amount not to e empty!" >Submit</button>)}
                {/* <button type="submit" className="btn trade-btn-a pl-5 pr-5 btn-hover" >Submit</button> */}
              </div>
            </form>
          </div>
        </div>
      </Modal>

      {/* DEBIT FUND MODAL */}
      <Modal open={openDebit} onClose={onCloseModalDebit}
        classNames={{
          overlay: 'customOverlay',
          modal: 'customModal_S',
        }} center  >
        <div className="card-header p-0">
          <h3 className="title-modal">Debit</h3>
        </div>
        <div className="modail-data">
          <div className="modal-body">
            <form onSubmit={debitModalSubmit}>
              <label htmlFor="usr">Username:</label>
              <div className="input-group">
                <span className="input-group-text">  <FormatListNumberedRtlIcon /></span>
                <input type='number' className="form-control" onChange={(e) => setUserNameDebit(e.target.value)} placeholder='000000' />
              </div>

              <label htmlFor="accountName">Wallets:</label>
              <div className="input-group">
                <span className="input-group-text">    <FormatListNumberedRtlIcon /></span>
                <select className="form-control" name="browser" id="browser" onChange={directionDebit} placeholder='Main Account'>
                  <option value='Main Account'> Main Account </option>
                  <option value="Trading Account"> Trading Account </option>
                  <option value="Funding Account"> Funding Account</option>
                </select>
              </div>

              <label htmlFor="usr">Amount:</label>
              <div className="input-group">
                <input type="number"
                  onChange={(e) => setAmountDebit(Number(e.target.value))}
                  className="form-control" id="usr" placeholder="Amount" />
                {/* <button type="button" className="btn_max">Max </button> */}
              </div>


              <div className="text-center mt-3">
                {amountDebit && userNameDebit ? (<button type="submit" className="btn trade-btn-a pl-5 pr-5 btn-hover" >Submit</button>) : (<button type="submit" disabled className="btn pl-5 pr-5 btn-hover" style={{ backgroundColor: 'gray' }} title="Username & Amount not to e empty!" >Submit</button>)}
                {/* <button type="submit" className="btn trade-btn-a pl-5 pr-5 btn-hover" >Submit</button> */}
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Gtc;
