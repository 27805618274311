import site_logo from '../Components/Assets/images/logo.png'
import arrowUp from '../Components/Assets/images/arrowupdark.svg';
import arrowDown from '../Components/Assets/images/arrowdown.svg.png';
import workInProgress from '../Components/Assets/images/workinprogress.png';
import NoImage from '../Components/Assets/images/noImage.jpg'


export const title = 'KissanX';
export const shorttitle = 'KSN';
export const logo = site_logo;
export const up = arrowUp
export const down = arrowDown
export const progress = workInProgress
export const noImageFound = NoImage
export const theme = '#e7c14b'
export const Site_link = "https://weboadmin.kissanx.com/";
export const API_URL = 'https://api-admin.kissanx.com/api/v1'
// export const API_URL = 'http://192.168.29.162:5001/api/v1';

export const IMAGE_URL = 'https://api.bitsta.io/images/';
export const TRON = 'https://tronscan.org/';
export const BSC = 'https://bscscan.com/';


