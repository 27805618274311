import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { get, patch } from '../../config/apis';
import { API_URL } from '../../config/Config';
import Spinner from '../Spinner';
import dateFormat from "dateformat";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import toasted from '../../config/toast';

function WithdrawHistory() {
    const {type} = useParams();
    const navigate = useNavigate();
    const [currentPage, setcurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [perPage, setPerPage] = useState(10);
    const [user, setUser] = useState('');
    const [records, setrecords] = useState(' ');
    const [search, setSearch] = useState('username')
    const [value, setValue] = useState('');
    const [remark, setremark] = useState('')
    const [rejectedId, setrejectedId] = useState('')

    const [sorting, setSorting] = useState({ key: '', ascending: false })
    let [p, setP] = useState(' ')


    const handleFetch = async () => {
        setUser(null)
        setLoading(true);
        var passcon = '';
        if (value !== '' && value !== undefined) {
            passcon = `&search=${search}&value=${value?.toLocaleLowerCase()}`;
        }
        var setPrice = '';
        if(type === 'Pending'){
            setPrice = '&adminStatus=Pending';
        }else if(type === 'Approved'){
            setPrice = `&adminStatus=Approved`;
        }else if(type === 'Rejected'){
            setPrice = `&adminStatus=Rejected`;
        }else{
            setPrice = ''
        }

        const res = await get(`${API_URL}/payout/withdraw-history?sort=${sorting.key}&page=${currentPage}&limit=${perPage+setPrice}${passcon}`,
            {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": 'Bearer ' + localStorage.getItem('Token')
            }
        )
        if (res?.status === 'success') {
            setUser(res);
            setrecords(res.total);
            setLoading(false);
            setP(res.page + 1)
        } else if (res?.status === 'error') {
            localStorage.clear()
            navigate("/login");
        }
    };

    const handleRejectSubmit = async (e) => {
        e.preventDefault();
        const postdata = {
            id: rejectedId,
            remark: remark,

        }
        const res = await patch(`${API_URL}/payout/reject_withdraw`, postdata, {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("Token")
        })
        if (res.status === 'success') {
            toasted.success(res.message);
            handleFetch();
            onCloseModal();
        } else {
            toasted.error(res.message)

        }

    }
    // const [approvId,setapproveId] = useState()
    const handleapprovedSubmit = async (approvId) => {
        // e.preventDefault();
        const postdata = {
            id: approvId,

        }
        const res = await patch(`${API_URL}/payout/approve_withdraw`, postdata, {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("Token")
        })
        if (res.status === 'success') {
            toasted.success(res.message);
            handleFetch();
        } else {
            toasted.error(res.message)

        }

    }


    useEffect(() => {
        handleFetch(); // eslint-disable-next-line
    }, [currentPage, perPage, search, value,type]);


    const handlePageChange = async (e) => {
        const newOffset = (e.selected + 1);
        setcurrentPage(newOffset);
    };


    const submitForm = async (e) => {
        e.preventDefault();
        handleFetch();
    };
    const applySorting = (key, ascending) => {
        if (ascending) {
            setSorting({ key: '', ascending: false });
            setSorting({ key: '-' + key, ascending: ascending });
            handleFetch();
        } else {
            setSorting({ key: '', ascending: false });
            setSorting({ key: key, ascending: ascending });
            handleFetch();

        }
    }

    const handleChange = async (e) => {
        e.preventDefault();
        setPerPage(e.target.value);
    }


    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);





    return (
        <>
            <div className="content-wrapper-new" >
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6"> <h1>WITHRAW HISTORY</h1> </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"> <Link to="/">Home</Link> </li>
                                    <li className="breadcrumb-item active">Withraw History</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="card card-primary card-outline card-outline-tabs mb-11">
                    <div className="card-body p-0">
                        <div className="tab-content" id="custom-tabs-four-tabContent">
                            <div className="tab-pane fade active show" id="detail-tab">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="">

                                            <div className="row mb-2">
                                                <div className="col-xl-10 col-lg-8 col-md-7 ">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="form-group m-2 text-nowrap">
                                                            <span>Show</span>
                                                            <select className="form-control  text-center w-auto d-inline" onChange={handleChange}>
                                                                <option defaultValue={10}>10</option>
                                                                <option value={20}>20</option>
                                                                <option value={50}>50</option>
                                                                <option value={100}>100</option>
                                                            </select>
                                                            <span>entries</span>
                                                        </div>
                                                        <form action="#" method="get" className="justify-content-end d-inline-flex align-items-center">
                                                            <select onChange={(e) => setSearch(e.target.value)} name="search" className="btn btn-active">
                                                                {/* <option defaultValue={''}>Search By</option> */}
                                                                <option value={'username'}>USERNAME</option>
                                                                <option value={'symbol'}>SYMBOL</option>
                                                                <option value={'hash'}>HASH</option>
                                                                {/* <option value={'amount'}>AMOUNT</option> */}

                                                            </select>
                                                        </form>
                                                    </div>



                                                </div>

                                                <div className="col-xl-2 col-lg-4 col-md-5">
                                                    <form action="#" method="get" className="">

                                                        <div className="card-tools ml-0 mr-0">
                                                            <div className="input-group input-group-sm" style={{ width: '100%' }}>
                                                                <input type="text" name="value" className="form-control float-right" placeholder="Search" onChange={(e) => setValue(e.target.value)} />
                                                                <div className="input-group-append">
                                                                    <button type="submit" className="btn btn-default" onClick={submitForm}>
                                                                        <i className="fas fa-search" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>


                                            <div className="card-body table-responsive p-0 content-wrapper_1">
                                                <table className="table text-nowrap">


                                                    {loading && <Spinner />}
                                                    <thead>
                                                        <tr>
                                                            <th> # </th>
                                                            <th onClick={() => applySorting('username', !sorting.ascending)}> USERNAME
                                                                <i className={sorting.key === '-username' || sorting.key === 'username' ? sorting.ascending === true && sorting.key === '-username' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>
                                                            <th onClick={() => applySorting('symbol', !sorting.ascending)}> SYMBOL
                                                                <i className={sorting.key === '-symbol' || sorting.key === 'symbol' ? sorting.ascending === true && sorting.key === '-symbol' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>
                                                            <th onClick={() => applySorting('amount', !sorting.ascending)}> AMOUNT
                                                                <i className={sorting.key === '-amount' || sorting.key === 'amount' ? sorting.ascending === true && sorting.key === '-amount' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>
                                                            <th onClick={() => applySorting('payable_amount', !sorting.ascending)}> PAYABLE AMOUNT
                                                                <i className={sorting.key === '-payable_amount' || sorting.key === 'payable_amount' ? sorting.ascending === true && sorting.key === '-payable_amount' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>

                                                            <th>FEE</th>
                                                            <th>WALLET ADDRESS</th>
                                                            <th>HASH</th>
                                                            <th>STATUS</th>

                                                            <th>REMARK</th>
                                                            {/* <th>COUNTRY</th>
                                                            <th>STATE</th>
                                                            <th>ADDRESS</th>
                                                            <th>PIN CODE</th>
                                                            <th onClick={() => applySorting('referal', !sorting.ascending)}> REFERAL
                                                                <i className={sorting.key === '-referal' || sorting.key === 'referal' ? sorting.ascending === true && sorting.key === '-referal' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>
                                                            <th onClick={() => applySorting('kyc_status', !sorting.ascending)}> KYC STATUS
                                                                <i className={sorting.key === '-kyc_status' || sorting.key === 'kyc_status' ? sorting.ascending === true && sorting.key === '-kyc_status' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th> */}
                                                            <th onClick={() => applySorting('created_at', !sorting.ascending)}> CREATED AT
                                                                <i className={sorting.key === '-created_at' || sorting.key === 'created_at' ? sorting.ascending === true && sorting.key === '-created_at' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>
                                                            <th onClick={() => applySorting('updated_at', !sorting.ascending)}> UPDATED AT
                                                                <i className={sorting.key === '-updated_at' || sorting.key === 'updated_at' ? sorting.ascending === true && sorting.key === '-updated_at' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                                                            </th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {loading && <Spinner />}

                                                        {user?.data?.data?.map((value, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{(p++)}</td>
                                                                    <td>{value.username}</td>
                                                                    <td>{value.symbol?.toUpperCase()}</td>

                                                                    <td>{value.amount}</td>
                                                                    <td>{value.payable_amount}</td>
                                                                    <td>{value?.fee}</td>
                                                                    <td>{value?.wallet_address}</td>
                                                                    <td>{value?.hash}</td>
                                                                    <td>{value?.status ==='Success'?'Success':'Pending'}</td>

                                                                    <td>{value.remark}</td>
                                                                    {/* <td>{value.KYCData?.[0].country}</td>
                                                                    <td>{value.KYCData?.[0]?.state}</td>
                                                                    <td>{value.KYCData?.[0]?.address}</td>
                                                                    <td>{value.KYCData?.[0]?.pin_code}</td>
                                                                    <td>{value.referal}</td>
                                                                    <td>{(value.KYCData?.[0]?.kyc_status === 'Approved') ? <span className="text-success font-weight-bold">Approved</span> : ((value.KYCData?.[0].kyc_status === 'Not Applied') ? (<span className="text-info font-weight-bold">Not Applied</span>) : ((value.KYCData?.[0].kyc_status === 'Rejected') ? (<span className="text-danger font-weight-bold">Rejected</span>) : ((value.KYCData?.[0].kyc_status === 'Pending') ? (<span className="text-warning font-weight-bold">Pending</span>) : "")))}</td> */}
                                                                    <td>{dateFormat(value.created_at, "UTC:dd-mm-yyyy TT hh:mm:ss")}</td>
                                                                    <td>{dateFormat(value.updated_at, "UTC:dd-mm-yyyy TT hh:mm:ss")}</td>
                                                                    <td>{value.admin_status === 'Pending' ? <div><button className='btn btn-success btn-sm' onClick={(e) => { handleapprovedSubmit(value._id); }}>Approve</button> | <button className='btn btn-danger btn-sm' onClick={() => { onOpenModal(); setrejectedId(value._id) }} >Rejected</button></div> : ''}</td>

                                                                </tr>
                                                            )
                                                        })}

                                                    </tbody>

                                                </table>
                                            </div>
                                            {records > perPage ?
                                                <div className="row mt-4">
                                                    <div className="col-12 text-right">
                                                        <div className="dataTables_info" id="example1_info" role="status" aria-live="polite" >
                                                            Showing {currentPage > 1 ? currentPage * perPage - perPage + 1 : currentPage} to {currentPage * perPage} of {records} entries
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <ReactPaginate
                                                            pageCount={user?.total > 0 ? Math.ceil(user?.total / perPage) : 1}
                                                            onPageChange={handlePageChange}
                                                            pageRangeDisplayed={1}
                                                            marginPagesDisplayed={2}
                                                            previousLabel={"PRV"}
                                                            nextLabel={"NEXT"}
                                                            containerClassName={"pagination justify-content-center"}
                                                            pageClassName={"page-item"}
                                                            pageLinkClassName={"page-link"}
                                                            previousClassName={"page-item"}
                                                            previousLinkClassName={"page-link"}
                                                            nextClassName={"page-item"}
                                                            nextLinkClassName={"page-link"}
                                                            breakClassName={"page-item"}
                                                            breakLinkClassName={"page-link"}
                                                            activeClassName={"active"}
                                                            renderOnZeroPageCount={1}
                                                        />
                                                    </div>

                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal open={open} onClose={onCloseModal} center
                classNames={{
                    overlay: 'Rejected_overlay',
                    modal: 'Rejected_modal_Main'
                }}>
                <h6> Rejected </h6>
                <div className='Rejected_modal'>
                    <form onSubmit={handleRejectSubmit}>
                        <div className='form-gorup'>
                            <label htmlFor='remark'> Remark</label>
                            <input type="text" value={remark} onChange={(e) => { setremark(e.target.value) }} id='remark' placeholder='Remark' />
                        </div>
                        <div className='button___area_ma'>
                            <button type='submit' className='btn'>Submit</button>

                        </div>

                    </form>

                </div>
            </Modal>


        </>
    );
}

export default WithdrawHistory