import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { get, post } from '../../config/apis';
import { API_URL, shorttitle } from '../../config/Config';
import toasted from '../../config/toast';






function Gtcform() {
    const { id } = useParams();
    // console.log(id);
    const navigate = useNavigate()
    const [bep, setBep] = useState(false);
    const [erc, setErc] = useState(false);
    const [trc, setTrc] = useState(false);
    const [gtc, setGtc] = useState(false);
    const [getValue, setGetValue] = useState('')
    const getSingle = async () => {
        const response = await get(`${API_URL}/market/getSingle/${id}`, {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("Token")
        })
        console.log('response',response);
        if (response) {
            setGetValue(response)
            setBep(response?.bep20?.status)
            setErc(response?.erc20?.status)
            setTrc(response?.trc20?.status)
            setGtc(response?.gtc20?.status)
            if(response?.bep20?.status === true){
                setBep20Status('enable')
            }
            if(response?.erc20?.status === true){
                setErc20Status('enable')
            }
            if(response?.trc20?.status === true){
                setTrc20Status('enable')
            }
            if(response?.gtc20?.status === true){
                setGtc20Status('enable')
            }
            if(response?.withdraw_status === true){
                setWithdrawStatus('enable')
            }
        } else {
            toasted.error('Error!')
        }
    }
    useEffect(() => {
        getSingle(); // eslint-disable-next-line
    }, []);



    const [minLimit, setMinLimit] = useState(0)
    const [maxLimit, setMaxLimit] = useState(0)
    const [fess, setFees] = useState(0)
    const [bep20Status, setBep20Status] = useState('disable')
    const [bep20Address, setBep20Address] = useState('')
    const [erc20Status, setErc20Status] = useState('disable')
    const [erc20Address, setErc20Address] = useState('')
    const [trc20Status, setTrc20Status] = useState('disable')
    const [trc20Address, setTrc20Address] = useState('')
    const [gtc20Status, setGtc20Status] = useState('disable')
    const [gtc20Address, setGtc20Address] = useState('')
    const [withdrawStatus, setWithdrawStatus] = useState('disable')
    const [withdrawMessage, setWithdrawMessage] = useState('')



    const handleSubmit = async (e) => {
        e.preventDefault();


        const postData = {
            id: id,
            minLimit: minLimit,
            maxLimit: maxLimit,
            withdraw_fee: fess,
            bep20_status: bep20Status,
            bep20_address: bep20Address,
            erc20_status: erc20Status,
            erc20_address: erc20Address,
            trc20_status: trc20Status,
            trc20_address: trc20Address,
            gtc20_status: gtc20Status,
            gtc20_address: gtc20Address,
            withdraw_status: withdrawStatus,
            withdrawMessage: withdrawMessage
        }

        const res = await post(`${API_URL}/market/withdrawSetting`, postData, {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("Token")
        })
        if (res?.status === 'success') {
            navigate('/gtcmarket');
            toasted.success(res.message)
        } else {
            toasted.error(res.message)
        }
    }




console.log('bep,trc,gtc,erc',bep,trc,gtc,erc,getValue);
    return (
        <div className="content-wrapper-new">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6"> <h1>GTC MARKET</h1> </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"> <Link to="/">Home</Link> </li>
                                <li className="breadcrumb-item active">GTC Market</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <div className="card card-primary card-outline card-outline-tabs " style={{ marginBottom: '100px' }}>
                <div className="card-body p-0">
                    <div className="tab-content" id="custom-tabs-four-tabContent">
                        <div className="tab-pane fade active show" id="detail-tab">
                            <div className="row">
                                <div className="col-12">

                                    <div className="">
                                        {/* <div className="card-header" style={{ backgroundColor: theme }}>
                                            <h3 className="card-title">ELP MARKET Form</h3>
                                        </div> */}
                                        <div className=" create-token with__form-card">

                                            <form action='#' onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <input type="hidden" value={getValue?.id} />
                                                            <label>Min Limit</label>
                                                            <input
                                                                type="number"
                                                                Value={getValue?.min_withdraw_limit}
                                                                className="form-control"
                                                                placeholder="Min Limit"
                                                                name="minLimit"
                                                                onChange={(e) => setMinLimit(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label>Max Limit</label>
                                                            <input
                                                                type="number"
                                                                Value={getValue?.max_withdraw_limit}
                                                                className="form-control"
                                                                placeholder="Max Limit"
                                                                name='maxLimit'
                                                                onChange={(e) => setMaxLimit(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label>Fees</label>
                                                            <input
                                                                type="number"
                                                                Value={getValue?.fees}
                                                                className="form-control"
                                                                placeholder="Fees"
                                                                name='withdraw_fee'
                                                                step="0.01" 
                                                                onChange={(e) => setFees(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className='column-main '>
                                                    <div className="column-1">
                                                        <div className="form-group form-c">
                                                            <label className='mb-1'>BEP20</label><br />
                                                            <select className="form-control  text-center w-auto d-inline mx-4 pl-3 pr-3" name='bep20_status'
                                                                onChange={(e) => {setBep20Status(e.target.value);setBep(e.target.value === 'enable'? true:false)}}
                                                            >
                                                                <option Value='enable' selected={bep === true ? true : false} >Enable</option>
                                                                <option Value='disable' selected={bep === false ? true : false} >Disable</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="column-1 column-right-s">
                                                        {bep === true ?
                                                            <div className="form-group form-c">
                                                                <label>Contract Address</label>
                                                                <input
                                                                    type="text"
                                                                    Value={getValue?.bep20?.contract_address}
                                                                    className="form-control"
                                                                    placeholder="bep20 Contract Address"
                                                                    name='bep20_address'
                                                                    onChange={(e) => setBep20Address(e.target.value)}
                                                                />
                                                            </div>
                                                            : null}

                                                    </div>
                                                </div>

                                                <div className='column-main'>
                                                    <div className="column-1">
                                                        <div className="form-group form-c">
                                                            <label>ERC20</label> <br />
                                                            <select className="form-control  text-center w-auto d-inline mx-2 pl-3 pr-3" name='erc20_status'
                                                                onChange={(e) => {setErc20Status(e.target.value);setErc(e.target.value === 'enable'? true:false)}}
                                                            >
                                                                <option Value='enable' selected={getValue?.erc20?.status === true ? 'selected' : ''} >Enable</option>
                                                                <option Value='disable' selected={getValue?.erc20?.status === false ? 'selected' : ''} >Disable</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="column-1 column-right-s">
                                                        {erc === true ?
                                                            <div className="form-group form-c">
                                                                <label>Contract Address</label>
                                                                <input
                                                                    type="text"
                                                                    Value={getValue?.erc20?.contract_address}
                                                                    className="form-control"
                                                                    placeholder="erc20 Contract Address"
                                                                    name='erc20_address'
                                                                    onChange={(e) => setErc20Address(e.target.value)}
                                                                />

                                                            </div>
                                                            : null}
                                                    </div>
                                                </div>
{/* TRC */}
                                                <div className='column-main'>
                                                    <div className="column-1">
                                                        <div className="form-group form-c">
                                                            <label>TRC20</label> <br />
                                                            <select className="form-control  text-center w-auto d-inline mx-2 pl-3 pr-3" name='trc20_status'
                                                                onChange={(e) => {setTrc20Status(e.target.value);setTrc(e.target.value === 'enable'? true:false)}}
                                                            >
                                                                <option Value='enable' selected={getValue?.trc20?.status === true ? 'selected' : ''}>Enable</option>
                                                                <option Value='disable' selected={getValue?.trc20?.status === false ? 'selected' : ''} >Disable</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="column-1 column-right-s">
                                                        {trc === true ?
                                                            <div className="form-group form-c">
                                                                <label>Contract Address</label>
                                                                <input
                                                                    type="text"
                                                                    Value={getValue?.trc20?.contract_address}
                                                                    className="form-control"
                                                                    placeholder="trc20 Contract Address"
                                                                    name='trc20_address'
                                                                    onChange={(e) => setTrc20Address(e.target.value)}
                                                                />
                                                            </div>
                                                            : null}
                                                    </div>
                                                </div>
                                                
                                                {/* GTC */}
                                                <div className='column-main'>
                                                    <div className="column-1">
                                                        <div className="form-group form-c">
                                                            <label>{shorttitle}20</label> <br />
                                                            <select className="form-control  text-center w-auto d-inline mx-2 pl-3 pr-3" name='sta20_status'
                                                                onChange={(e) => {setGtc20Status(e.target.value);setGtc(e.target.value === 'enable'? true:false)}}
                                                            >
                                                                <option Value='enable' selected={getValue?.sta20?.status === true ? 'selected' : ''} >Enable</option>
                                                                <option Value='disable' selected={getValue?.sta20?.status === false ? 'selected' : ''}>Disable</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="column-1 column-right-s">
                                                        {gtc === true ?
                                                            <div className="form-group form-c">
                                                                <label>Contract Address</label>
                                                                <input
                                                                    type="text"
                                                                    Value={getValue?.gtc20?.contract_address}
                                                                    className="form-control"
                                                                    placeholder="sta20 Contract Address"
                                                                    name='sta20_address'
                                                                    onChange={(e) => setGtc20Address(e.target.value)}
                                                                />
                                                            </div>
                                                            : null}
                                                    </div>
                                                </div>

                                                <div className='column-main'>
                                                    <div className="column-1">
                                                        <div className="form-group form-c">
                                                            <label>Withdraw Status</label> <br />
                                                            <select className="form-control  text-center w-auto d-inline mx-2 pl-3 pr-3" name='withdraw_status'
                                                                onChange={(e) => setWithdrawStatus(e.target.value)}
                                                            >
                                                                <option value='enable' selected={getValue?.withdraw_status === true ? true : false}>Enable</option>
                                                                <option value='disable' selected={getValue?.withdraw_status === false ? true : false}>Disable</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label>Withdraw Message</label>
                                                            <textarea
                                                                className="form-control"
                                                                // value={getValue?.withdraw_message} 
                                                                rows={4}
                                                                placeholder="Withdraw Message"
                                                                name='withdrawMessage'
                                                                onChange={(e) => setWithdrawMessage(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" className="btn btn-withdraw h-auto" value={'submit'}>Submit</button>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Gtcform
