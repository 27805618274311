import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { API_URL, theme } from "../../config/Config";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../Spinner";
import { get, post } from "../../config/apis";
import dateFormat from "dateformat";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
// import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import toasted from "../../config/toast";


function ALIUsersDetail() {

  const navigate = useNavigate();
  const [currentPage, setcurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [perPage, setPerPage] = useState(10);
  const [user, setUser] = useState('');
  const [records, setrecords] = useState(' ');
  const [search, setSearch] = useState('username')
  const [value, setValue] = useState('')
  const [sorting, setSorting] = useState({ key: '', ascending: false })
  let [p, setP] = useState(' ')


  const handleFetch = async () => {
    setUser(null)
    setLoading(true);
    var passcon = '';
    if (value !== '' && value !== undefined) {
      passcon = `&search=${search}&value=${value?.toLocaleLowerCase()}`;
    }
    const res = await get(`${API_URL}/home/allUsers?sort=${sorting.key}&page=${currentPage}&limit=${perPage}${passcon}`,
      {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": 'Bearer ' + localStorage.getItem('Token')
      }
    )
    if (res?.status === 'success') {
      setUser(res);
      setrecords(res.total);
      setLoading(false);
      setP(res.page + 1)
    } else if (res?.status === 'error') {
      localStorage.clear()
      navigate("/login");
    }
  };



  const handlePageChange = async (e) => {
    const newOffset = (e.selected + 1);
    setcurrentPage(newOffset);
  };

  useEffect(() => {
    handleFetch(); // eslint-disable-next-line
  }, [currentPage, perPage, search, value]);

  const submitForm = async (e) => {
    e.preventDefault();
    handleFetch();
  };
  const applySorting = (key, ascending) => {
    if (ascending) {
      setSorting({ key: '', ascending: false });
      setSorting({ key: '-' + key, ascending: ascending });
      handleFetch();
    } else {
      setSorting({ key: '', ascending: false });
      setSorting({ key: key, ascending: ascending });
      handleFetch();

    }
  }

  const handleChange = async (e) => {
    e.preventDefault();
    setPerPage(e.target.value);
  }

  ////////////////////////
  const [walletUser, setWalletUser] = useState()
  const [balanceData, setBalanceData] = useState()
  const assetModalSubmit = async (userName, type) => {
    setBalanceData(null);
    setLoading(true);
    setWalletUser(userName)

    const para = {
      'username': userName,
      "type": type
    }
    const res = await post(`${API_URL}/income/get-balance`, para, {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("Token")
    })

    if (res) {
      setBalanceData(res.market)
      setLoading(false);
      // toasted.success(res.message)
    } else {
      toasted.error(res.message)
    }

  }


  const checkWalletBalance = (e) => {
    let WalletType = e.target.value;
    assetModalSubmit(walletUser, WalletType)
  }

  const openModalAsent = (username) => {
    onOpenModal()
    assetModalSubmit(username, 'all')
  }

  // Asset button Modal 
  const [openModal, setOpenModal] = useState(false);
  const onOpenModal = () => setOpenModal(true);
  const onCloseModal = () => setOpenModal(false);

  return (
    <>
      <div className="content-wrapper-new" >
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6"> <h1>ALL USERS</h1> </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"> <Link to="/">Home</Link> </li>
                  <li className="breadcrumb-item active">All Users</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <div className="card card-primary card-outline card-outline-tabs mb-11">
          <div className="card-body p-0">
            <div className="tab-content" id="custom-tabs-four-tabContent">
              <div className="tab-pane fade active show" id="detail-tab">
                <div className="row">
                  <div className="col-12">
                    <div className="">

                      <div className="row mb-2">
                        <div className="col-xl-10 col-lg-8 col-md-7 ">
                          <div className="d-flex justify-content-between">
                            <div className="form-group m-2 text-nowrap">
                              <span>Show</span>
                              <select className="form-control  text-center w-auto d-inline" onChange={handleChange}>
                                <option defaultValue={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                              <span>entries</span>
                            </div>
                            <form action="#" method="get" className="justify-content-end d-inline-flex align-items-center">
                              <select onChange={(e) => setSearch(e.target.value)} name="search" className="btn btn-active">
                                {/* <option defaultValue={''}>Search By</option> */}
                                <option value={'username'}>USERNAME</option>
                                <option value={'referal'}>REFERAL</option>
                                <option value={'email'}>EMAIL</option>
                                <option value={'number'}>PHONE</option>

                              </select>
                            </form>
                          </div>



                        </div>

                        <div className="col-xl-2 col-lg-4 col-md-5">
                          <form action="#" method="get" className="">

                            <div className="card-tools ml-0 mr-0">
                              <div className="input-group input-group-sm" style={{ width: '100%' }}>
                                <input type="text" name="value" className="form-control float-right" placeholder="Search" onChange={(e) => setValue(e.target.value)} />
                                <div className="input-group-append">
                                  <button type="submit" className="btn btn-default" onClick={submitForm}>
                                    <i className="fas fa-search" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>


                      <div className="card-body table-responsive p-0 content-wrapper_1">
                        <table className="table text-nowrap">

                          <thead>
                            <tr>
                              <th> # </th>
                              <th onClick={() => applySorting('username', !sorting.ascending)}> USERNAME
                                <i className={sorting.key === '-username' || sorting.key === 'username' ? sorting.ascending === true && sorting.key === '-username' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('email', !sorting.ascending)}> EMAIL
                                <i className={sorting.key === '-email' || sorting.key === 'email' ? sorting.ascending === true && sorting.key === '-email' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('number', !sorting.ascending)}> NUMBER
                                <i className={sorting.key === '-number' || sorting.key === 'number' ? sorting.ascending === true && sorting.key === '-number' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th>COUNTRY</th>
                              <th>STATE</th>
                              <th>ADDRESS</th>
                              <th>PIN CODE</th>
                              <th>ACTION</th>
                              <th onClick={() => applySorting('referal', !sorting.ascending)}> REFERAL
                                <i className={sorting.key === '-referal' || sorting.key === 'referal' ? sorting.ascending === true && sorting.key === '-referal' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('kyc_status', !sorting.ascending)}> KYC STATUS
                                <i className={sorting.key === '-kyc_status' || sorting.key === 'kyc_status' ? sorting.ascending === true && sorting.key === '-kyc_status' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('created_at', !sorting.ascending)}> CREATED AT
                                <i className={sorting.key === '-created_at' || sorting.key === 'created_at' ? sorting.ascending === true && sorting.key === '-created_at' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('updated_at', !sorting.ascending)}> UPDATED AT
                                <i className={sorting.key === '-updated_at' || sorting.key === 'updated_at' ? sorting.ascending === true && sorting.key === '-updated_at' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                            </tr>
                          </thead>

                          <tbody >

                            {loading && <Spinner />}
                            {user?.data?.data?.map((value, i) => {
                              return (
                                <tr key={i}>
                                  <td>{(p++)}</td>
                                  <td>{value.username}</td>
                                  <td>{value.email}</td>
                                  <td>{value.phone > 0 ? '+' + value.phone : ''}</td>
                                  <td>{value.KYCData[0].country}</td>
                                  <td>{value.KYCData[0].state}</td>
                                  <td>{value.KYCData[0].address}</td>
                                  <td>{value.KYCData[0].pin_code}</td>
                                  <td><button className="btn btn-sm px-3 text-dark mx-2" style={{ backgroundColor: theme, fontWeight: 'bold' }} onClick={() => openModalAsent(value.username)} > View Assets </button></td>
                                  <td>{value.referal}</td>
                                  {(value.KYCData[0].kyc_status === 'Approved') ? <td className="text-success font-weight-bold">Approved</td> : ((value.KYCData[0].kyc_status === 'Not Applied') ? (<td className="text-info font-weight-bold">Not Applied</td>) : ((value.KYCData[0].kyc_status === 'Rejected') ? (<td className="text-danger font-weight-bold">Rejected</td>) : ((value.KYCData[0].kyc_status === 'Pending') ? (<td className="text-warning font-weight-bold">Pending</td>) : "")))}
                                  <td>{dateFormat(value.created_at, "UTC:dd-mm-yyyy TT hh:mm:ss")}</td>
                                  <td>{dateFormat(value.updated_at, "UTC:dd-mm-yyyy TT hh:mm:ss")}</td>
                                </tr>
                              )
                            })}
                          </tbody>

                        </table>
                      </div>
                      {records > perPage ?
                        <div className="row mt-4">
                          <div className="col-12 text-right">
                            <div className="dataTables_info" id="example1_info" role="status" aria-live="polite" >
                              Showing {currentPage > 1 ? currentPage * perPage - perPage + 1 : currentPage} to {currentPage * perPage} of {records} entries
                            </div>
                          </div>

                          <div className="col-12">
                            <ReactPaginate
                              pageCount={user?.total > 0 ? Math.ceil(user?.total / perPage) : 1}
                              onPageChange={handlePageChange}
                              pageRangeDisplayed={1}
                              marginPagesDisplayed={2}
                              previousLabel={"PRV"}
                              nextLabel={"NEXT"}
                              containerClassName={"pagination justify-content-center"}
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                              renderOnZeroPageCount={1}
                            />
                          </div>

                        </div>
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* CREDIT FUND MODAL */}
      <Modal open={openModal} onClose={onCloseModal}
        classNames={{
          overlay: 'customOverlay',
          modal: 'customModal_Sa',
        }} center  >
        <div className="card-header p-0"
        // style={{width: '383px'}}
        >
          <h5 className="title-modal">List of Available Token with Balance</h5>
        </div>
        <div className="modail-data">
          <div className="modal-body p-0 pt-3 pb-2">
            <form>
              <div className="list-modal-a">
                <label htmlFor="accountName">Wallet Types:</label>
                <div className="input-group">
                  <span className="input-group-text"> <AccountBalanceWalletIcon /></span>
                  <select className="form-control" name="List-modal" id="List-modal" onChange={checkWalletBalance}>
                    <option defaultValue='all'> All Account </option>
                    <option value='main_account'> Main Account </option>
                    <option value="trade_account"> Trading Account </option>
                    <option value="funding_account"> Funding Account</option>
                  </select>
                </div>
              </div>
            </form>


            <div className="card-body  p-0 table-modal">
              <div className="table-responsive">
                <table className="table text-nowrap">

                  <thead>
                    <tr>
                      {/* <th > COIN NAME </th> */}
                      <th > SYMBOL </th>
                      <th > BALANCE </th>
                      <th > CURRENT PRICE </th>
                      <th > TOTAL COIN </th>
                    </tr>
                  </thead>

                  <tbody>
                    {loading && <Spinner />}
                    {balanceData?.map((value, index) => {
                      return (
                        <tr key={index}>
                          {/* <td> {value.name} <span>{value.coin_id}</span> </td> */}
                          <td> {value.symbol.toUpperCase()} </td>
                          <td> ${value.usdt_income.toFixed(2)} </td>
                          <td> ${value.current_price.toFixed(4)} </td>
                          <td> ${value.total_coin.toFixed(3)} </td>
                        </tr>

                      )
                    })}
                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ALIUsersDetail;