import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { Toaster } from 'react-hot-toast';


import {
  PrivateRoutes, Login, Dashboard, Allusers, Approvedkyc, Pendingkyc, Rejectedkyc, Headerbar, Sidebar, Footer,
  MainMarket, GtcMarket, Gtcform, FundDeposits, CreateToken, ManualToken, WithdrawHistory, Activitylog, SellOrders, BuyOrders, P2pHistory, TokenEditForm, SwapHistory
} from './Components';
// import ALIUsersDetail from './Components/ALIUsers/ALIUsersDetail';
import ALIUsersDetail from './Components/ALIUsers/ALIUsersDetail';
import DepositHistory from './Components/FundMangement/DepositHistory';
import AllDepositList from './Components/FundMangement/AllDepositList';
import AvalAssetsHistory from './Components/FundMangement/AvalAssetsHistory';


function PrivateLayout() {
  return (
    <>
      <Headerbar />
      <Sidebar />
      <Outlet />
      <Footer />
    </>
  )
}
function PublicLayout() {
  return <Outlet />
}

function App() {



  return (
    <>
      <div className="wrapper">

        <BrowserRouter basename='/'>
          <Routes>

            <Route element={<PrivateRoutes />}>
              <Route path="/" element={<PrivateLayout />}>
                <Route index element={<Dashboard />} />
                <Route exact path="*" element={<Dashboard />} />
                <Route exact path="/allusers" element={<Allusers />} />
                {/* <Route exact path='/aliusersdetail' element={<ALIUsersDetail />}/> */}
                <Route exact path='/aliusersdetail' element={<ALIUsersDetail />} />
                <Route exact path="/approvedkyc" element={<Approvedkyc />} />
                <Route exact path="/Pendingkyc" element={<Pendingkyc />} />
                <Route exact path="/rejectedkyc" element={<Rejectedkyc />} />
                <Route exact path="/mainmarket" element={<MainMarket />} />
                <Route exact path="/manualToken" element={<ManualToken />} />
                <Route exact path="/manualToken/:id" element={<TokenEditForm />} />
                <Route exact path="/gtcmarket" element={<GtcMarket />} />
                <Route exact path="/gtcmarket/:id" element={<Gtcform />} />
                <Route exact path="/fund-deposits" element={<FundDeposits />} />
                <Route exact path="/deposits-history" element={<DepositHistory />} />
                <Route exact path="/available-assets-history" element={<AvalAssetsHistory />} />

                <Route exact path="/deposits-history/:symbol" element={<DepositHistory />} />
                <Route exact path='/deposit-List' element={<AllDepositList />} />
                <Route exact path="/createtoken" element={<CreateToken />} />
                <Route exact path="/withdrawhistory/:type" element={<WithdrawHistory />} />
                <Route exact path="/activitylog" element={<Activitylog />} />
                <Route exact path="/sell-orders" element={<SellOrders />} />
                <Route exact path='/buy-orders' element={<BuyOrders />} />
                <Route exact path="/p2phistory" element={<P2pHistory />} />
                <Route exact path="/swap-history" element={<SwapHistory />} />
              </Route>
            </Route>

            <Route path="/login" element={<PublicLayout />}>
              <Route index element={<Login />} />
            </Route>

          </Routes>
        </BrowserRouter>
        <Toaster />


      </div>
    </>

  );
}

export default App;
