import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { API_URL } from "../../config/Config";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../Spinner";
import { get} from "../../config/apis";
import dateFormat from "dateformat";

function SwapHistory() {

  const navigate = useNavigate();
  const [currentPage, setcurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [perPage, setPerPage] = useState(10);
  const [user, setUser] = useState('');
  const [records, setrecords] = useState(' ');
  const [search, setSearch] = useState('username')
  const [value, setValue] = useState('')
  const [sorting, setSorting] = useState({ key: '', ascending: false })
  let [p, setP] = useState(' ')

  const handleFetch = async () => {
    setUser(null)
    setLoading(true);
    var passcon = '';
    if (value !== '' && value !== undefined) {
      passcon = `&search=username&value=${value?.toLocaleLowerCase()}`;
    }
    const res = await get(`${API_URL}/report/users-swap-history?sort=${sorting.key}&page=${currentPage}&limit=${perPage}${passcon}`,
      {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": 'Bearer ' + localStorage.getItem('Token')
      }
    )
    if (res?.status === 'success') {
      if (res.total < 10) {
        setcurrentPage(1);
    }
      setUser(res);
      setrecords(res.total);
      setLoading(false);
      setP(res.page + 1)
    } else if (res?.status === 'error') {
      localStorage.clear()
      navigate("/login");
    }
  };



  const handlePageChange = async (e) => {
    const newOffset = (e.selected + 1);
    setcurrentPage(newOffset);
  };

  useEffect(() => {
    handleFetch(); // eslint-disable-next-line
  }, [currentPage, perPage, search, value]);

  const submitForm = async (e) => {
    e.preventDefault();
    handleFetch();
  };
  const applySorting = (key, ascending) => {
    if (ascending) {
      setSorting({ key: '', ascending: false });
      setSorting({ key: '-' + key, ascending: ascending });
      handleFetch();
    } else {
      setSorting({ key: '', ascending: false });
      setSorting({ key: key, ascending: ascending });
      handleFetch();

    }
  }

  const handleChange = async (e) => {
    e.preventDefault();
    setPerPage(e.target.value);
  }

  return (
    <>
      <div className="content-wrapper-new" >
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6"> <h1>SWAP HISTORY</h1> </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"> <Link to="/">Home</Link> </li>
                  <li className="breadcrumb-item active">Swap History</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <div className="card card-primary card-outline card-outline-tabs mb-11">
          <div className="card-body p-0">
            <div className="tab-content" id="custom-tabs-four-tabContent">
              <div className="tab-pane fade active show" id="detail-tab">
                <div className="row">
                  <div className="col-12">
                    <div className="">

                      <div className="row mb-2">
                        <div className="col-xl-10 col-lg-8 col-md-7 ">
                          <div className="d-flex justify-content-between">
                            <div className="form-group m-2 text-nowrap">
                              <span>Show</span>
                              <select className="form-control  text-center w-auto d-inline" onChange={handleChange}>
                                <option defaultValue={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                              <span>entries</span>
                            </div>
                            <form action="#" method="get" className="justify-content-end d-inline-flex align-items-center">
                              <select onChange={(e) => setSearch(e.target.value)} name="search" className="btn btn-active">
                                {/* <option defaultValue={''}>Search By</option> */}
                                <option value={'username'}>USERNAME</option>

                              </select>
                            </form>
                          </div>



                        </div>

                        <div className="col-xl-2 col-lg-4 col-md-5">
                          <form action="#" method="get" className="">

                            <div className="card-tools ml-0 mr-0">
                              <div className="input-group input-group-sm" style={{ width: '100%' }}>
                                <input type="text" name="value" className="form-control float-right" placeholder="Search" onChange={(e) => setValue(e.target.value)} />
                                <div className="input-group-append">
                                  <button type="submit" className="btn btn-default" onClick={submitForm}>
                                    <i className="fas fa-search" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>


                      <div className="card-body table-responsive p-0 content-wrapper_1">
                        <table className="table text-nowrap">

                          <thead>
                            <tr>
                              <th> # </th>
                              <th onClick={() => applySorting('username', !sorting.ascending)}> USERNAME
                                <i className={sorting.key === '-username' || sorting.key === 'username' ? sorting.ascending === true && sorting.key === '-username' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('outGoingAmount', !sorting.ascending)}> STA TOKEN
                                <i className={sorting.key === '-outGoingAmount' || sorting.key === 'outGoingAmount' ? sorting.ascending === true && sorting.key === '-outGoingAmount' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('outGoing_price', !sorting.ascending)}> STA TOKEN PRICE
                                <i className={sorting.key === '-outGoing_price' || sorting.key === 'outGoing_price' ? sorting.ascending === true && sorting.key === '-outGoing_price' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('incomingAmount', !sorting.ascending)}> STA COIN
                                <i className={sorting.key === '-incomingAmount' || sorting.key === 'incomingAmount' ? sorting.ascending === true && sorting.key === '-incomingAmount' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('incoming_price', !sorting.ascending)}> STA COIN PRICE
                                <i className={sorting.key === '-incoming_price' || sorting.key === 'incoming_price' ? sorting.ascending === true && sorting.key === '-incoming_price' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>
                              <th onClick={() => applySorting('created_at', !sorting.ascending)}> DATE
                                <i className={sorting.key === '-created_at' || sorting.key === 'created_at' ? sorting.ascending === true && sorting.key === '-created_at' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                              </th>



                            </tr>
                          </thead>

                          <tbody >

                            {loading && <Spinner />}
                            {user?.data?.data?.map((value, i) => {
                              return (
                                <tr key={i}>
                                  <td>{(p++)}</td>
                                  <td>{value?.username}</td>
                                  <td>{value?.outGoingAmount?.toFixed(3)}</td>
                                  <td>{value?.outGoing_price}</td>
                                  <td>{value?.incomingAmount?.toFixed(3)}</td>
                                  <td>{value?.incoming_price}</td>
                                  <td>{dateFormat(value?.created_at, "UTC:dd-mm-yyyy TT hh:mm:ss")}</td>

                                </tr>
                              )
                            })}
                          </tbody>

                        </table>
                      </div>
                      {records > perPage ?
                        <div className="row mt-4">
                          <div className="col-12 text-right">
                            <div className="dataTables_info" id="example1_info" role="status" aria-live="polite" >
                              Showing {currentPage > 1 ? currentPage * perPage - perPage + 1 : currentPage} to {currentPage * perPage} of {records} entries
                            </div>
                          </div>

                          <div className="col-12">
                            <ReactPaginate
                              pageCount={user?.total > 0 ? Math.ceil(user?.total / perPage) : 1}
                              onPageChange={handlePageChange}
                              pageRangeDisplayed={1}
                              marginPagesDisplayed={2}
                              previousLabel={"PRV"}
                              nextLabel={"NEXT"}
                              containerClassName={"pagination justify-content-center"}
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                              renderOnZeroPageCount={1}
                            />
                          </div>

                        </div>
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default SwapHistory;