import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { get } from "../../config/apis";
import { API_URL} from "../../config/Config";
import toasted from "../../config/toast";
import Spinner from "./../Spinner";


function AllDepositList() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();

  const handleFetch = async () => {
    setUser(null)
    setLoading(true);
    const res = await get(`${API_URL}/income/deposit-token-info`,
      {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": 'Bearer ' + localStorage.getItem('Token')
      }
    )
    if (res?.status === 'success') {
      setUser(res.data);
      setLoading(false)
    } else if (res?.status === 'error') {
      localStorage.clear()
      navigate("/login");
      toasted.error('You are logged out,please login again!')
    }
  }

  useEffect(() => {
    handleFetch(); // eslint-disable-next-line
  }, []);

  return (
    <div>

      <div className="content-wrapper-new">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Deposit List</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/deposit-List">Deposit List</Link>
                  </li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">

          <div className="container-fluid">
            <div className="row">

              {loading && <Spinner />}

              {user?.data?.map((data, i) => (
                <div className="col-xl-3 col-md-6 col-lg-4" key={i}>
                  <div className="card">
                    <div className="inner">
                      <Link to={ "/deposits-history/"+data?.symbol} className="stretched-link box-link">
                        <h5>{data?.symbol?.toUpperCase()}</h5>
                        <h5 className="account-balance" > {data?.totalAmount?.toFixed(4)}</h5>
                      </Link>
                    </div>
                    <img src={data.logo} className="img__logo" alt={data.logo} />
                  </div>
                </div>
              ))}

            </div>

          </div>
        </section>
      </div>
    </div>
  );
}

export default AllDepositList;


// {(condition 1 ? ' ' :(condition 2 ? ' ' :(condition 3 ? ' ' : (condition 4 ? ' ' ))))}